var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: { icon: "mdi-account-multiple", title: "Usuários" },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return [
              _c("emc-excel-import-icon", {
                attrs: { param: "user_imports" },
                on: {
                  onCompleted: function($event) {
                    return _vm.getData()
                  }
                }
              }),
              _c("s-icon-report-download", {
                attrs: { "show-message": "", report: "5" }
              }),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: { color: "indigo darken-1", icon: "" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      _vm.showForm = true
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-plus")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v(" Cadastrar usuário ")])]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("emc-table-items", {
        attrs: {
          headers: _vm.headers,
          params: _vm.params,
          collections: _vm.collections,
          meta: _vm.meta,
          itemDelete: _vm.itemDelete,
          itemEdit: _vm.model,
          loading: _vm.loading
        },
        on: {
          "update:params": function($event) {
            _vm.params = $event
          },
          "update:collections": function($event) {
            _vm.collections = $event
          },
          "update:meta": function($event) {
            _vm.meta = $event
          },
          "update:itemDelete": function($event) {
            _vm.itemDelete = $event
          },
          "update:item-delete": function($event) {
            _vm.itemDelete = $event
          },
          "update:itemEdit": function($event) {
            _vm.model = $event
          },
          "update:item-edit": function($event) {
            _vm.model = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item.name",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("EmcBaseAvatar", {
                  staticClass: "mr-2",
                  attrs: { text: item.initials, src: item.url_avatar, size: 36 }
                }),
                _vm._v(" " + _vm._s(item.name) + " ")
              ]
            }
          }
        ])
      }),
      _c("emc-avatar-show", {
        attrs: {
          model: _vm.selectedUser,
          show: _vm.showImage,
          source: _vm.selectedUser ? _vm.selectedUser.url_avatar : null
        },
        on: {
          "update:show": function($event) {
            _vm.showImage = $event
          }
        }
      }),
      _c("emc-alert-modal", {
        attrs: { text: _vm.text, show: _vm.show },
        on: {
          "update:show": function($event) {
            _vm.show = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "error",
                      loading: _vm.deleting,
                      disabled: _vm.deleting
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.deleteItem()
                      }
                    }
                  },
                  [
                    _vm._v("Excluir "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-modal-form", {
        attrs: {
          maxWidth: "50%",
          title: _vm.titleForm,
          icon: "mdi-account",
          show: _vm.showForm
        },
        on: {
          "update:show": function($event) {
            _vm.showForm = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "form",
            fn: function() {
              return [
                _c(
                  "v-tabs",
                  {
                    staticClass: "ma-0",
                    model: {
                      value: _vm.tab,
                      callback: function($$v) {
                        _vm.tab = $$v
                      },
                      expression: "tab"
                    }
                  },
                  [
                    _c("v-tab", { key: "main" }, [_vm._v("Dados do Usuário")]),
                    _c("v-tab", { key: "campuses" }, [
                      _vm._v("Permissões no Campus")
                    ])
                  ],
                  1
                ),
                _c(
                  "v-tabs-items",
                  {
                    model: {
                      value: _vm.tab,
                      callback: function($$v) {
                        _vm.tab = $$v
                      },
                      expression: "tab"
                    }
                  },
                  [
                    _c(
                      "v-tab-item",
                      { key: "main" },
                      [
                        _c(
                          "v-container",
                          [
                            _c(
                              "v-row",
                              { staticClass: "mt-1" },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "12" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        autofocus: "",
                                        dense: "",
                                        error: _vm.errors.length > 0,
                                        "error-messages": _vm.errors.name,
                                        label: "Nome*",
                                        required: ""
                                      },
                                      model: {
                                        value: _vm.model.name,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "name", $$v)
                                        },
                                        expression: "model.name"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        dense: "",
                                        error: _vm.errors.length > 0,
                                        "error-messages": _vm.errors.email,
                                        label: "Email"
                                      },
                                      model: {
                                        value: _vm.model.email,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "email", $$v)
                                        },
                                        expression: "model.email"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c("v-text-field", {
                                      directives: [
                                        {
                                          name: "mask-phone",
                                          rawName: "v-mask-phone.br",
                                          modifiers: { br: true }
                                        }
                                      ],
                                      attrs: {
                                        dense: "",
                                        error: _vm.errors.length > 0,
                                        "error-messages":
                                          _vm.errors.mobile_phone,
                                        label: "Celular"
                                      },
                                      model: {
                                        value: _vm.model.mobile_phone,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "mobile_phone",
                                            $$v
                                          )
                                        },
                                        expression: "model.mobile_phone"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm.acesso &&
                            _vm.acesso.grupo &&
                            !_vm.acesso.grupo.external_login
                              ? _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", sm: "6" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            type: "password",
                                            dense: "",
                                            error: _vm.errors.length > 0,
                                            "error-messages":
                                              _vm.errors.password,
                                            label: "Senha"
                                          },
                                          model: {
                                            value: _vm.model.password,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.model,
                                                "password",
                                                $$v
                                              )
                                            },
                                            expression: "model.password"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", sm: "6" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            type: "password",
                                            dense: "",
                                            error: _vm.errors.length > 0,
                                            "error-messages":
                                              _vm.errors.password_confirmation,
                                            label: "Confirmar senha"
                                          },
                                          model: {
                                            value:
                                              _vm.model.password_confirmation,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.model,
                                                "password_confirmation",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "model.password_confirmation"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c("sys-select-role", {
                                      attrs: {
                                        model: _vm.model.roles,
                                        error: _vm.errors.length > 0,
                                        errors: _vm.errors.roles,
                                        label: "Perfil de acesso",
                                        multiple: "",
                                        disabled: _vm.disableFields,
                                        "return-object": ""
                                      },
                                      on: {
                                        "update:model": function($event) {
                                          return _vm.$set(
                                            _vm.model,
                                            "roles",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c("sys-select-departament", {
                                      attrs: {
                                        model: _vm.model.departaments,
                                        error: _vm.errors.length > 0,
                                        errors: _vm.errors.departaments,
                                        label: "Departamento",
                                        multiple: "",
                                        disabled: _vm.disableFields,
                                        "select-all": ""
                                      },
                                      on: {
                                        "update:model": function($event) {
                                          return _vm.$set(
                                            _vm.model,
                                            "departaments",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                !_vm.isAnima()
                                  ? _c(
                                      "v-col",
                                      { attrs: { cols: "12", sm: "6" } },
                                      [
                                        _c("sys-select-course", {
                                          attrs: {
                                            model: _vm.model.courses,
                                            error: _vm.errors.length > 0,
                                            errors: _vm.errors.courses,
                                            label: "Cursos",
                                            multiple: "",
                                            dense: "",
                                            disabled: _vm.disableFields,
                                            selectAll: ""
                                          },
                                          on: {
                                            "update:model": function($event) {
                                              return _vm.$set(
                                                _vm.model,
                                                "courses",
                                                $event
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c("v-switch", {
                                      attrs: {
                                        inset: "",
                                        dense: "",
                                        label: "Usuário Ativo"
                                      },
                                      model: {
                                        value: _vm.model.active,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "active", $$v)
                                        },
                                        expression: "model.active"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("small", [_vm._v("*Campos obrigatórios")]),
                        _vm.model.id
                          ? _c("div", { staticClass: "mt-4" }, [
                              _c(
                                "small",
                                [
                                  _c("v-icon", { attrs: { "x-small": "" } }, [
                                    _vm._v("mdi-account")
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.model.user
                                          ? _vm.model.user.name
                                          : null
                                      )
                                  )
                                ],
                                1
                              )
                            ])
                          : _vm._e(),
                        _vm.model.id
                          ? _c("div", [
                              _c(
                                "small",
                                [
                                  _c("v-icon", { attrs: { "x-small": "" } }, [
                                    _vm._v("mdi-clock-outline")
                                  ]),
                                  _vm._v(" " + _vm._s(_vm.model.updated_at))
                                ],
                                1
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "v-tab-item",
                      { key: "campuses" },
                      [
                        _c(
                          "v-container",
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "12" } },
                                  [
                                    _c(
                                      "v-sheet",
                                      {
                                        attrs: { elevation: "5", rounded: "xl" }
                                      },
                                      [
                                        _c(
                                          "v-container",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _vm.isAnima()
                                                  ? _c(
                                                      "v-col",
                                                      [
                                                        _c("sys-select-brand", {
                                                          staticClass: "ml-3",
                                                          attrs: {
                                                            model: _vm.brands,
                                                            label:
                                                              "Filtro de Marca",
                                                            multiple: "",
                                                            disabled:
                                                              _vm.loadingCampuses
                                                          },
                                                          on: {
                                                            "update:model": function(
                                                              $event
                                                            ) {
                                                              _vm.brands = $event
                                                            }
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c("v-switch", {
                                                      staticClass: "ml-3",
                                                      attrs: {
                                                        inset: "",
                                                        color: "purple",
                                                        loading:
                                                          _vm.loadindSelectCampuses,
                                                        label:
                                                          "Todos" +
                                                          " (" +
                                                          _vm
                                                            .selectedIndexCampuses
                                                            .length +
                                                          "/" +
                                                          _vm.campuses.length +
                                                          ")",
                                                        disabled:
                                                          _vm.loadingCampuses
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.selectCampuses
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.selectAllCampuses,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.selectAllCampuses = $$v
                                                        },
                                                        expression:
                                                          "selectAllCampuses"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c("v-divider", {
                                                      attrs: { inset: "" }
                                                    }),
                                                    _vm.loadingCampuses
                                                      ? _c(
                                                          "v-progress-linear",
                                                          {
                                                            staticClass:
                                                              "mb-0 mt-0",
                                                            attrs: {
                                                              indeterminate: "",
                                                              color: "blue"
                                                            }
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm.errors.campuses
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "text-center red--text"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.errors
                                                                  .campuses[0]
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "v-list",
                                                      {
                                                        attrs: {
                                                          "two-line": ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-group",
                                                          {
                                                            attrs: {
                                                              "active-class":
                                                                "primary--text",
                                                              multiple: ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.selectedIndexCampuses,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.selectedIndexCampuses = $$v
                                                              },
                                                              expression:
                                                                "selectedIndexCampuses"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "emc-util-scroll",
                                                              {
                                                                attrs: {
                                                                  height: "40vh"
                                                                }
                                                              },
                                                              _vm._l(
                                                                _vm.campuses,
                                                                function(
                                                                  campus,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "span",
                                                                    {
                                                                      directives: [
                                                                        {
                                                                          name:
                                                                            "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value:
                                                                            _vm
                                                                              .brands
                                                                              .length ==
                                                                              0 ||
                                                                            _vm.brands
                                                                              .map(
                                                                                function(
                                                                                  b
                                                                                ) {
                                                                                  return b.id
                                                                                }
                                                                              )
                                                                              .includes(
                                                                                campus.brand_id
                                                                              ),
                                                                          expression:
                                                                            "brands.length == 0 || brands.map(b => b.id).includes(campus.brand_id)"
                                                                        }
                                                                      ],
                                                                      key: index,
                                                                      staticClass:
                                                                        "ma-0 pa-0"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-list-item",
                                                                        {
                                                                          attrs: {
                                                                            disabled:
                                                                              _vm.loadingCampuses
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "default",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var active =
                                                                                    ref.active
                                                                                  return [
                                                                                    _c(
                                                                                      "v-list-item-content",
                                                                                      [
                                                                                        _c(
                                                                                          "v-list-item-title",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                campus.name
                                                                                              )
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _vm.isAnima()
                                                                                          ? _c(
                                                                                              "v-list-item-subtitle",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "text--primary"
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "small",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        campus
                                                                                                          .brand
                                                                                                          .name
                                                                                                      )
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          : _vm._e(),
                                                                                        _vm.isAnima()
                                                                                          ? _c(
                                                                                              "v-list-item-subtitle",
                                                                                              [
                                                                                                _c(
                                                                                                  "small",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        campus
                                                                                                          .institution
                                                                                                          .name
                                                                                                      )
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          : _vm._e()
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _c(
                                                                                      "v-list-item-action",
                                                                                      [
                                                                                        _vm.isAnima()
                                                                                          ? _c(
                                                                                              "v-list-item-action-text",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "text-right"
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "small",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "ID: " +
                                                                                                        _vm._s(
                                                                                                          campus.id
                                                                                                        )
                                                                                                    )
                                                                                                  ]
                                                                                                ),
                                                                                                campus.code
                                                                                                  ? _c(
                                                                                                      "small",
                                                                                                      [
                                                                                                        _c(
                                                                                                          "br"
                                                                                                        ),
                                                                                                        _vm._v(
                                                                                                          "Código: " +
                                                                                                            _vm._s(
                                                                                                              campus.code
                                                                                                            )
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  : _vm._e()
                                                                                              ]
                                                                                            )
                                                                                          : _vm._e(),
                                                                                        !active
                                                                                          ? _c(
                                                                                              "v-icon",
                                                                                              {
                                                                                                attrs: {
                                                                                                  color:
                                                                                                    "grey lighten-1"
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " mdi-star-outline "
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          : _c(
                                                                                              "v-icon",
                                                                                              {
                                                                                                attrs: {
                                                                                                  color:
                                                                                                    "yellow darken-3"
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " mdi-star "
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        }
                                                                      ),
                                                                      index <
                                                                      _vm
                                                                        .campuses
                                                                        .length -
                                                                        1
                                                                        ? _c(
                                                                            "v-divider",
                                                                            {
                                                                              key: index
                                                                            }
                                                                          )
                                                                        : _vm._e()
                                                                    ],
                                                                    1
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "primary",
                      loading: _vm.updating,
                      disabled: _vm.updating
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.save()
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.model.id ? "Atualizar" : "Cadastrar") + " "
                    ),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-content-save")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-snackbar", {
        attrs: {
          show: !!_vm.message.length,
          message: _vm.message,
          color: _vm.error ? "error" : "success"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }